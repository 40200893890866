<template>
    <div>该页面暂无信息</div>

</template>
<script>
export default {
    name:'accidentContent',
    data(){
        return {
            
        }
    }
}
</script>